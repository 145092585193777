import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';
import LogRocket from "logrocket";
import Button from "@material-ui/core/Button";
import CustomInputField from '../../hoc/CustomInputField';
import AppLogo from '../../hoc/AppLogo';
import SubTitle from '../../hoc/SubTitle';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import HelpLink from '../../hoc/HelpLink';
import { valdaUrl, deviceId, debugOTP, token, pinCode, accountID } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import {
  deviceIDRandomGenerator,
  storeTokenData,
  handleResponseCode,
  checkEmailisValid,
  showAlert,
  showHTMLAlert,
  handleException,
  validateWithFaceOrTouchId,
  logEvent
} from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToSignupOne: false,
      navigateToForgotPasswordOne: false,
      navigateToVerifyCode: false,
      navigateToHome: false,
      navigateToCreatePin: false,
      navigateToAuthenticatePin: false,
      email: "",
      password: "",
      errorEmail: false,
      emailErrorMessage: "",
      errorpassword: false,
      passwordErrorMessage: "",
    };
  }

  async componentDidMount() {
    if (window.cordova) {
      window.FirebasePlugin.setScreenName("Login");
    }
    // Check if token and pin number is available than do quick login
    if (localStorage.getItem(token) !== null) {
      let showPinScreen = true;
      if (window.cordova) {
        // validate with touch or face id.
        showPinScreen = await validateWithFaceOrTouchId();
        showPinScreen = !showPinScreen.return;
      }

      LogRocket.identify(localStorage.getItem(accountID));

      if (showPinScreen) {
        if (localStorage.getItem(pinCode) !== null) {
          // validate with pin code.
          this.setState({
            navigateToAuthenticatePin: true,
          });
        }
      } else {
        // Navigate to home page on validation.
        this.setState({
          navigateToHome: true,
        });
      }
    }
  }

  // Handle Login continue
  handleContinue = () => {
    // Validation for Login Inputs
    if (this.state.email === '') {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "Email can't be blank.",
      });
      return;
    }

    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: 'Email is not valid',
      });
      return;
    }
    if (this.state.password === '') {
      this.setState({
        errorpassword: true,
        passwordErrorMessage: "Password can't be blank.",
      });
      return;
    }

    // Login Api Call
    const url = `${valdaUrl}login`;
    let deviceID = 0;
    // eslint-disable-next-line
    if (localStorage.getItem(deviceId) && localStorage.getItem(deviceId) != "null") {
      deviceID = localStorage.getItem(deviceId);
    } else {
      deviceID = deviceIDRandomGenerator();
      console.log(deviceID);
      localStorage.setItem(deviceId, deviceID);
    }
    const apiData = {
      email: this.state.email,
      password: this.state.password,
      device_id: deviceID,
      debug: debugOTP,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response_code === 201 && response.result) {
            if (response.otp) showAlert('Verification Code', response.otp);
            this.setState({
              navigateToVerifyCode: true,
            });
          }
          if (response.response_code === 200 && response.result) {
            storeTokenData(response.data);
            if (window.cordova) {
              // Go to get pin code to do quick login, if app is running on mobile AND there isn't fingerprint or face id available
              window.Fingerprint.isAvailable((result) => {
                this.setState({
                  navigateToHome: true,
                });
              },(error) => {
                this.setState({
                  navigateToCreatePin: true,
                });
              });
            } else {
              // Go to home page if app is running in browser
              this.setState({
                navigateToHome: true,
              });
            }
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleForgotPassword = () => {
    this.setState({
      navigateToForgotPasswordOne: true,
    });
  };

  handleDontHaveAccount = () => {
    this.setState({
      navigateToSignupOne: true,
    });
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: '',
    });
  };

  handleHelpLoggingIn = () => {
    showHTMLAlert('Need help logging in?','Passwords should contain at least 10 characters, be a mix of both upper & lower case & include a number & symbol.<br/><br/>For more information <a class="helpLink" onclick="if (window.cordova) {window.cordova.InAppBrowser.open(\'https://www.valdaenergy.com/help-and-faqs/login-help-for-valda-energy-app\',\'_blank\',\'location=no\');} else {window.open(\'https://www.valdaenergy.com/help-and-faqs/login-help-for-valda-energy-app\', \'_system\');}">click here</a>');
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorpassword: false,
      passwordErrorMessage: '',
    });
  };

  render() {
    if (this.state.navigateToSignupOne) {
      return <Redirect to="/signup" />;
    }

    if (this.state.navigateToForgotPasswordOne) {
      return <Redirect to="/forgotpasswordone" />;
    }

    if (this.state.navigateToVerifyCode) {
      return <Redirect to="/verifycode" />;
    }

    if (this.state.navigateToHome) {
      logEvent('login');
      return (
        <Redirect
          to={{
            pathname: '/home',
            state: { validated: true },
          }}
        />
      );
    }

    if (this.state.navigateToCreatePin) {
      return <Redirect to="/createpin" />;
    }

    if (this.state.navigateToAuthenticatePin) {
      return <Redirect to="/validatepin" />;
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="SIGN IN" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
          className="formContainer"
        >
          <AppLogo />
          <SubTitle titleText="Log in to your account" />
          <CustomInputField
            fieldName="Email"
            fieldType="email"
            fieldValue={this.state.email}
            isError={this.state.errorEmail}
            errorMessage={this.state.emailErrorMessage}
            textChange={this.handleEmailChange}
          />
          <CustomInputField
            fieldName="Password"
            fieldType="password"
            fieldValue={this.state.password}
            isError={this.state.errorpassword}
            errorMessage={this.state.passwordErrorMessage}
            textChange={this.handlePasswordChange}
          />
          <CustomButton
            btnName="CONTINUE"
            btnClick={this.handleContinue}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
          <Button
            variant="contained"
            className="signupBtn"
            onClick={this.handleDontHaveAccount}
            fullWidth
          >
            First time log in – create an account
          </Button>
          <CustomLink
            linkText="FORGOT PASSWORD"
            linkClick={this.handleForgotPassword}
          />
          <HelpLink
            linkText="NEED HELP LOGGING IN?"
            linkClick={this.handleHelpLoggingIn}
          />
        </Grid>
      </div>
    );
  }
}

export default Login;
