/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import swal from "sweetalert";

import LeftMenu from '../../components/LeftMenu/LeftMenu';
import * as actions from '../../store/actions/index';
import { token, pinCode, valdaUrl } from "../../constants";
import { POSTSeaglass } from "../../Global/Apis/ServerApi";
import {
  handleResponseCode,
  showAlert,
  handleException,
  removeTokenData,
  logEvent,
  logEventWithAccountID
} from "../../utility";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToLogin: false,
      navigateToAuthenticatePin: false,
    };
  }

  componentDidMount() {
    if (window.cordova) {
      window.FirebasePlugin.setScreenName("Home");
    }
    // Set event on app resume
    document.addEventListener('resume', this.handleOnResume, false);

    console.log('this.props::: ', this.props.location.state);
    // Check if token and pin number is available than do quick login
    if (
      !window.cordova &&
      (this.props.location.state === undefined ||
        this.props.location.state.validated !== true)
    ) {
      removeTokenData();
      this.setState({
        navigateToLogin: true,
      });
    }

    if (
      window.cordova &&
      (this.props.location.state === undefined ||
        this.props.location.state.validated !== true) &&
      localStorage.getItem(token) !== null &&
      localStorage.getItem(pinCode) !== null
    ) {
      // validate with pin code.
      this.setState({
        navigateToAuthenticatePin: true,
      });
      return;
    }

    // call an action method
    this.props.onGetAccountDetail();
    const url = `${valdaUrl}checkIfAllSmartMeters`;
    POSTSeaglass(url)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data) {
            console.log(`The result of checkifallsmartmeters: ${response.response.data.allSmart}`);
            if(!response.response.data.allSmart) {
              if (response.response.data.showAlert) {
                const el = document.createElement("div");
                el.innerHTML = response.response.data.alertText;
                swal({
                  title: response.response.data.alertTitle,
                  content: el,
                  icon: response.response.data.alertIcon,
                  closeOnClickOutside: false,
                  className: "js-sma-modal",
                  buttons: {
                    confirm: {
                      text: "BOOK HERE",
                      value: true,
                      visible: true,
                      closeModal: true,
                      className: "sma-book",
                    },
                    cancel: {
                      text: "Close",
                      value: null,
                      visible: true,
                      closeModal: true,
                      className: "sma-close",
                    },
                  },
                }).then((value) => {
                  if (value === true) {
                    logEvent('smart_meter_popup_book');
                    if (window.cordova) {
                      window.cordova.InAppBrowser.open(
                        "https://www.valdaenergy.com/smart-install-portal",
                        "_blank",
                        "location=no"
                      );
                    } else {
                      window.open(
                        "https://www.valdaenergy.com/smart-install-portal",
                        "_blank"
                      );
                    }
                  } else {
                    logEventWithAccountID("smart_meter_popup_dismiss");
                  }
                });
              }
            }
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // Handle event on app resume
  handleOnResume = () => {
    setTimeout(() => {
      this.setState({
        navigateToLogin: true,
      });
    }, 99);
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    // Check on home page that we don't have token then user have to redirect to Login
    if (localStorage.getItem(token) === null) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToAuthenticatePin) {
      return <Redirect to="/validatepin" />;
    }

    return <LeftMenu />;
  }
}

// Get accountDetail object from Redux Store
const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

// call an action for redux
const mapDispatchToProps = (dispatch) => ({
  onGetAccountDetail: () => dispatch(actions.getAccountDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
