import React, { Suspense } from 'react';

import './App.css';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import LogRocket from 'logrocket';
import Home from './containers/Home/Home';
import Login from './containers/Login/Login';
import SignupOne from './containers/Signup/SignupOne';
import SignupTwo from './containers/Signup/SignupTwo';
import ForgotPasswordOne from './containers/ForgotPassword/ForgotPasswordOne';
import ForgotPasswordTwo from './containers/ForgotPassword/ForgotPasswordTwo';
import VerifyCode from './containers/VerifyCode/VerifyCode';
import SuccessCallback from './containers/Callbacks/SuccessCallback';
import FailureCallback from './containers/Callbacks/FailureCallback';
import NoConnection from './containers/StaticPages/NoConnection';
import ContactUs from './components/ContactUs/ContactUs';
import PinNumber from './containers/PinNumber/PinNumber';

LogRocket.init("strofw/valda-app", {
  dom: {
    baseHref: "https://app.valdaenergy.com/",
  },
});

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/signup" render={() => <SignupOne />} />
            <Route path="/signuptwo" render={() => <SignupTwo />} />
            <Route
              path="/forgotpasswordone"
              render={() => <ForgotPasswordOne />}
            />
            <Route
              path="/forgotpasswordtwo"
              render={() => <ForgotPasswordTwo />}
            />
            <Route path="/verifycode" render={() => <VerifyCode />} />
            <Route path="/paymentapproved" render={() => <SuccessCallback />} />
            <Route path="/paymentdeclined" render={() => <FailureCallback />} />
            <Route path="/noconnection" render={() => <NoConnection />} />
            <Route
              path="/contactus"
              render={() => <ContactUs showMenuBtn={false} showBackBtn />}
            />
            <Route path="/createpin" render={() => <PinNumber createPin />} />
            <Route
              path="/validatepin"
              render={() => <PinNumber createPin={false} />}
            />
            <Route path="/home" component={Home} />
          </Switch>
        </Suspense>
        <div
          id="spinner"
          className="loaderContainer"
          style={{ display: 'none' }}
        >
          <div className="loader" />
        </div>
      </Router>
    </div>
  );
}

export default App;
