import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import UpdatePassword from './UpdatePassword';
import ResetPIN from './ResetPIN';
import CustomAccordion from '../../hoc/CustomAccordion';
import CustomTitlebar from '../../hoc/CustomTitlebar';
import CustomButton from '../../hoc/CustomButton';
import { contactPersonName, accountID } from '../../constants';
import { logScreenView, logEvent } from '../../utility';

let info = [];

class AccountInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDeleteAccount = () => {
    logEvent('request_delete_account');
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        "https://www.valdaenergy.com/app-deletion-request",
        "_blank",
        "location=yes"
      );
    } else {
      window.open(
        "https://www.valdaenergy.com/app-deletion-request",
        "_system"
      );
    }
  };

  // Create Account Array
  createAccountArr = () => {
    if (this.props.accountDetail) {
      // Handle Address if some field have Null
      const buildingNo = this.props.accountDetail.address.buildingnumber;
      const buildingName = this.props.accountDetail.address.buildingname;
      const thoroughFare = this.props.accountDetail.address.thoroughfare;
      const postTown = this.props.accountDetail.address.posttown;
      const postCode = this.props.accountDetail.address.postcode;
      // eslint-disable-next-line prefer-destructuring
      const county = this.props.accountDetail.address.county;

      const address = [
        buildingNo,
        buildingName,
        thoroughFare,
        postTown,
        postCode,
        county,
      ]
        .filter(Boolean)
        .join(", ");
      console.log("Address :", address);

      info = [
        {
          title: "Company Details",
          displayFields: [
            {
              label: "Company Name",
              value: this.props.accountDetail.name,
            },
            {
              label: "Property Details",
              value: address,
            },
            {
              label: "Contact Phone Number",
              value: this.props.accountDetail.contact.mobile,
            },
          ],
        },
        // {
        //   title: 'Personal Information',
        //   displayFields: [
        //     {
        //       label: 'Tarrif Name',
        // value: `${this.props.accountDetail.contact.firstname}
        // ${this.props.accountDetail.contact.lastname}`,
        //     },
        //   ],
        // },
        {
          title: "Contact Information",
          displayFields: [
            {
              label: "Contact Name",
              value: `${this.props.accountDetail.contact.firstname} ${this.props.accountDetail.contact.lastname}`,
            },
            {
              label: "Email",
              value: this.props.accountDetail.contact.email,
            },
            {
              label: "Contact Phone Number",
              value: this.props.accountDetail.contact.mobile,
            },
          ],
        },
      ];
    }
  };

  render() {
    this.createAccountArr();
    logScreenView("Account Information");
    const accountInfoListitems = info.map((singleInfoObj, index) => (
      <CustomAccordion
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        blockTitle={singleInfoObj.title}
        displayList={singleInfoObj.displayFields}
      />
    ));

    const contactPersonNameVal = localStorage.getItem(contactPersonName);
    const accountIDVal = localStorage.getItem(accountID);

    return (
      <div className="desktopHalfScreenContainer">
        <div>
          <CustomTitlebar
            menuClick={this.props.menuClick}
            showMenuBtn
            titleText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div>
                <div>
                  <span style={{ fontSize: "1.3em" }} className="boldText">
                    {contactPersonNameVal}
                  </span>
                </div>
                <div>{`Account Number: ID ${accountIDVal}`}</div>
              </div>
            }
          />
        </div>
        <div className="scrollingContainer accountInfoScroller">
          <div className="nintyPercentWidth">
            <List component="nav" aria-label="main mailbox folders">
              {accountInfoListitems}
            </List>
            <UpdatePassword />
            <ResetPIN />
            <CustomButton
              btnName="Delete Account"
              btnClick={this.handleDeleteAccount}
              showFullWidth
              endBtnIcon={<ArrowForwardIcon />}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

export default connect(mapStateToProps)(AccountInformation);

AccountInformation.propTypes = {
  accountDetail: PropTypes.objectOf(PropTypes.object),
  menuClick: PropTypes.func,
};

AccountInformation.defaultProps = {
  accountDetail: {},
  menuClick: () => false,
};
