import React, { Component } from 'react';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

import { valdaUrl, contactPersonName } from "../../constants";
import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import {
  handleResponseCode,
  showAlert,
  handleException,
  getElecOrGasMeterData,
  logScreenView
} from '../../utility';
import SitesListItem from './SitesListItem';
import CustomTitlebar from '../../hoc/CustomTitlebar';

class Sites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
    };
  }

  componentDidMount() {
    // Api call for MeterPoint
    const url = `${valdaUrl}meterPoints`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data) {
            console.log('Site Page');
            this.setState({
              sitesList: response.response.data,
            });
            this.setSitesData(response.response.data);
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  }

  // set Sites Data - meterPointRef & estimatedAnnualUsage from electric or gas
  setSitesData = async (response) => {
    response.forEach((element, index) => {
      const siteData = {
        meterid: element.productid,
        utility: element.utility,
        sourceid: element.sourceid
      };
      // Function for api which call gas or electric meter api for each site data
      getElecOrGasMeterData(siteData).then((res) => {
        console.log('res ::: ', res);
        // eslint-disable-next-line react/no-access-state-in-setstate
        const sitesListArr = this.state.sitesList;
        sitesListArr[index].meterPointRef = res.meterPointRef;
        sitesListArr[index].estimatedAnnualUsage = res.estimatedAnnualUsage;
        this.setState({
          sitesList: sitesListArr,
        });
      });
    });
  };

  render() {
    logScreenView("Sites");
    let listItems;
    const sitesArr = this.state.sitesList;
    if (sitesArr && sitesArr.length) {
      let showSites = true;
      console.log(sitesArr);
      sitesArr.forEach((site) => {
        if ((typeof site.meterPointRef).toString() === 'undefined') {
          showSites = false;
        }
      });

      if (showSites) {
        // eslint-disable-next-line arrow-body-style
        listItems = this.state.sitesList.map((item) => {
          return <SitesListItem record={item} key={item.mpan} />;
        });
      }
    }

    const contactPersonNameVal = localStorage.getItem(contactPersonName);

    return (
      <div>
        <div className="dontShowOnDesktop">
          <CustomTitlebar
            menuClick={this.props.menuClick}
            showMenuBtn
            titleText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div>
                <span className="boldText">{`Welcome ${contactPersonNameVal}`}</span>
              </div>
            }
          />
        </div>
        <div className="scrollingContainer sitesPageScroller">
          <div>
            <List>{listItems}</List>
          </div>
        </div>
      </div>
    );
  }
}

export default Sites;

Sites.propTypes = {
  menuClick: PropTypes.func,
};

Sites.defaultProps = {
  menuClick: false,
};
