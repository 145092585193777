import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

import CustomInputField from '../../hoc/CustomInputField';
import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import { valdaUrl, accountID } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import { handleResponseCode, showAlert, handleException } from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class SignupTwo extends Component {
  constructor(props) {
    super(props);
    console.log('Props:', this.props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      errorFirstName: false,
      firstNameErrorMessage: '',
      errorLastName: false,
      lastNameErrorMessage: '',
      errorpassword: false,
      passwordErrorMessage: '',
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: '',
      navigateToLogin: false,
    };
  }

  // Validation For Sign Up Page
  validateSignUp = () => {
    if (this.state.firstName === '') {
      this.setState({
        errorConfirmPassword: true,
        firstNameErrorMessage: "FirstName can't be blank.",
      });
      return false;
    }
    if (this.state.lastName === '') {
      this.setState({
        errorLastName: true,
        lastNameErrorMessage: "LastName can't be blank.",
      });
      return false;
    }

    if (this.state.password === '') {
      this.setState({
        errorpassword: true,
        passwordErrorMessage: "Password can't be blank.",
      });
      return false;
    }

    // Use ZXCVBN library to check password strenth
    const passwordScore = zxcvbn(this.state.password);
    if (passwordScore.score < 2) {
      // password score must be >= 2
      this.setState({
        errorpassword: true,
        passwordErrorMessage:
          'Password is weak so please reenter strong password.',
      });
      return false;
    }

    if (this.state.confirmPassword === '') {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage: "Repeat Password can't be blank.",
      });
      return false;
    }

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        errorConfirmPassword: true,
        confirmPasswordErrorMessage: 'Repeat Password is not same as Password',
      });
      return false;
    }
    return true;
  };

  // SignUp Api Call
  handleSignup = () => {
    if (!this.validateSignUp()) {
      return;
    }

    const url = `${valdaUrl}signup`;
    const apiData = {
      email: localStorage.getItem('email'),
      account_id: localStorage.getItem(accountID),
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      password: this.state.password,
      password_confirm: this.state.confirmPassword,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          this.setState({
            navigateToLogin: true,
          });
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleGetInTouch = () => {
    window.open('tel:03303904510', '_system');
  };

  // Input field handle - First Name
  handleFirstNameChange = (newValue) => {
    this.setState({
      firstName: newValue,
      errorFirstName: false,
      firstNameErrorMessage: '',
    });
  };

  // Input field handle - Last Name
  handleLastNameChange = (newValue) => {
    this.setState({
      lastName: newValue,
      errorLastName: false,
      lastNameErrorMessage: '',
    });
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorpassword: false,
      passwordErrorMessage: '',
    });
  };

  // Input field handle - Confirm Password
  handleConfirmPasswordChange = (newValue) => {
    this.setState({
      confirmPassword: newValue,
      errorConfirmPassword: false,
      confirmPasswordErrorMessage: '',
    });
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="SIGN UP" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <div className="centerText marginTop">
            Create an account please enter a few details.
          </div>
          <CustomInputField
            fieldName="First Name"
            fieldType="text"
            fieldValue={this.state.firstName}
            isError={this.state.errorFirstName}
            errorMessage={this.state.firstNameErrorMessage}
            textChange={this.handleFirstNameChange}
          />
          <CustomInputField
            fieldName="Last Name"
            fieldType="text"
            fieldValue={this.state.lastName}
            isError={this.state.errorLastName}
            errorMessage={this.state.lastNameErrorMessage}
            textChange={this.handleLastNameChange}
          />
          <CustomInputField
            fieldName="Password"
            fieldType="password"
            fieldValue={this.state.password}
            isError={this.state.errorpassword}
            errorMessage={this.state.passwordErrorMessage}
            textChange={this.handlePasswordChange}
          />
          <CustomInputField
            fieldName="Repeat Password"
            fieldType="password"
            fieldValue={this.state.confirmPassword}
            isError={this.state.errorConfirmPassword}
            errorMessage={this.state.confirmPasswordErrorMessage}
            textChange={this.handleConfirmPasswordChange}
          />
          <CustomButton
            btnName="SIGN-UP"
            btnClick={this.handleSignup}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
          <CustomLink
            linkText="GET IN TOUCH"
            linkClick={this.handleGetInTouch}
          />
        </Grid>
      </div>
    );
  }
}

export default SignupTwo;
