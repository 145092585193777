/* eslint-disable arrow-body-style */
import * as actionType from './actionTypes';
import { accountID, seaglassURL } from '../../constants';

import { POSTSeaglass } from '../../Global/Apis/ServerApi';
import { handleResponseCode, showAlert, handleException } from '../../utility';

// Action for setAccountDetail in Redux store
export const setAccountDetail = (accountDetails) => {
  return {
    type: actionType.GET_ACCOUNT_DETAIL_SUCCESS,
    accountDetail: accountDetails,
  };
};

// Action for when api call is failed
export const getAccountDetailFailed = () => {
  return {
    type: actionType.GET_ACCOUNT_DETAIL_FAIL,
  };
};

// Api Call for GetAccountDetailByID
export const getAccountDetail = () => {
  return (dispatch) => {
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(accountID)}`;
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          dispatch(setAccountDetail(response.response.data));
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };
};

// Action for Logout
export const logOut = () => {
  return {
    type: actionType.LOGOUT,
  };
};
