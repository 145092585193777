import React from 'react';
import moment from 'moment';
import swal from 'sweetalert';

// eslint-disable-next-line object-curly-newline
import {
  accountID,
  encryptedAccountID,
  refreshToken,
  token,
  loginCount,
  monthNames,
  deviceId,
  nextPaymentDate,
  nextPaymentValue,
  seaglassURL,
  isRefreshTokenApiCall,
} from "./constants";
// eslint-disable-next-line import/no-cycle
import { RefreshTokenAPI, POSTSeaglass } from './Global/Apis/ServerApi';
import NextPayment from './hoc/NextPayment';

// Give Month Name from String Date like '2020-06-15'
export const strToGiveMonthName = (strDate) => {
  const d = new Date(strDate); // converts the string into date object
  const monthIndex = d.getMonth();
  const monthName = monthNames[monthIndex];
  return monthName;
};

// Generate a Random Device Id - 16 digit
export const deviceIDRandomGenerator = () => {
  const minm = 1000000000000000;
  const maxm = 9999999999999999;
  const randomNo = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  return randomNo;
};

// Store data in LocalStorage like accountId, RefreshToken and token while login or signup
export const storeTokenData = (data) => {
  localStorage.setItem(accountID, data.account_id);
  localStorage.setItem(encryptedAccountID, data.encrypted_account_id);
  localStorage.setItem(refreshToken, data.refresh_token);
  localStorage.setItem(token, data.token);
  localStorage.setItem(loginCount, data.login_count);
  localStorage.setItem(isRefreshTokenApiCall, false);
};

// Remove all data from local storge except deviceId
export const removeTokenData = () => {
  const deviceIdOld = localStorage.getItem(deviceId);
  localStorage.clear();
  localStorage.setItem(deviceId, deviceIdOld);
};

// For HandleResponseCode and it return true or false
export const handleResponseCode = (response) => {
  console.log('handleResponseCode');
  if (
    // eslint-disable-next-line operator-linebreak
    response.response_code >= 200 &&
    // eslint-disable-next-line operator-linebreak
    response.response_code < 300 &&
    response.result
  ) {
    return true;
  }
  if (response.response_code === 400) {
    if (localStorage.getItem(isRefreshTokenApiCall) === 'false') {
      localStorage.setItem(isRefreshTokenApiCall, true);
      RefreshTokenAPI();
    }
    return false;
  }
  return false;
};

// convert string date to particular format like Do MMMM YYYY
export const dateToDateFormat = (strDate) =>
  // Date Format : "2020-06-15"
  // eslint-disable-next-line implicit-arrow-linebreak
  moment(strDate).format('Do MMMM YYYY');

// check Email Is valid or not by its expression checking
export const checkEmailisValid = (email) => {
/*
  const pattern = new RegExp(
    // eslint-disable-next-line comma-dangle
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  */

  // TK - Updating regex for e-mail validation to be much less complex - also
  // to allow longer ends (i.e. .network instead of .com)
  const pattern = new RegExp(
    '(.+)@(.+){2,}.(.+){2,}'
  );

  if (pattern.test(email)) {
    return true;
  }
  return false;
};

// Return number in proper currency format
export const currencyFormat = (num) => {
  const numberVal = parseFloat(num);
  return numberVal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

// Return Payment Section Block with its amount and display date
export const getNextPaymentSection = () => {
  let nextPaymentBlock = '';
  let nextPaymentDateValue = '';
  if (
    // eslint-disable-next-line operator-linebreak
    localStorage.getItem(nextPaymentDate) !== null &&
    // eslint-disable-next-line operator-linebreak
    localStorage.getItem(nextPaymentDate) !== '' &&
    // eslint-disable-next-line operator-linebreak
    new Date(localStorage.getItem(nextPaymentDate)).toString() !==
      'Invalid Date'
  ) {
    const nextPaymentAmountValue = currencyFormat(
      parseFloat(localStorage.getItem(nextPaymentValue))
    );
    nextPaymentDateValue = localStorage.getItem(nextPaymentDate);
    nextPaymentDateValue = dateToDateFormat(nextPaymentDateValue);
    nextPaymentBlock = (
      <NextPayment
        displayDate={nextPaymentDateValue}
        displayAmount={nextPaymentAmountValue}
      />
    );
  }
  return nextPaymentBlock;
};

// Show alert using sweetalert Plugin
export const showAlert = (title, message) => {
  swal(title, message);
};

export const showHTMLAlert = (title,html) => {
  const el = document.createElement('div');
  el.innerHTML = html;
  swal({title: title, content: el});
}

export const showHTMLAlertNoClose = (title, html) => {
  const el = document.createElement("div");
  el.innerHTML = html;
  swal({ title, content: el, buttons: false });
};

export const logScreenView = (screenName) => {
  if (window.cordova) {
    window.FirebasePlugin.setScreenName(screenName);
  }
};

export const logEvent = (eventName) => {
  if (window.cordova) {
    window.FirebasePlugin.logEvent(eventName, {});
  }
};
export const logEventWithAccountID = (eventName) => {
  if (window.cordova) {
    window.FirebasePlugin.logEvent(eventName, { account_id: accountID});
  }
};

// Show spinnerß
export const showSpinner = (displaySpinner) => {
  if (displaySpinner) document.getElementById('spinner').style.display = '';
  else document.getElementById('spinner').style.display = 'none';
};

// return last 12 month data for chart
export const filterLastTwelveMonthsData = (objArr, date) => {
  const filteredArr = [];
  const today = new Date();
  const year = today.getFullYear() - 1;
  const month = today.getMonth() + 1 >= 12 ? 0 : today.getMonth() + 1;
  const compareDate = new Date(year, month, 1);
  let elementDate = '';
  objArr.forEach((element) => {
    elementDate = new Date(element[date]);
    if (elementDate >= compareDate) {
      filteredArr.push(element);
    }
  });
  return filteredArr;
};

// Sort an array by date
export const sortObjArrayDateWise = (objArr, date) => {
  const sortedObjArr = objArr.sort(
    (a, b) => new Date(a[date]) - new Date(b[date])
  );
  return sortedObjArr;
};

// Handle Exception like if api fall in to catch block so we call it to show error message
export const handleException = (error) => {
  console.log(error);
  if (typeof error === 'string') showAlert('Error', error);
  else showAlert('Error', JSON.stringify(error));
};

// Open NoConnection page when internetconnection not available
export const handleNoConnection = () => {
  window.open('#/noconnection', '_self');
};

// get electic or gasmeter data for display in site page
export const getElecOrGasMeterData = (siteData) => {
  let url = `${seaglassURL}sgCore/products/${siteData.meterid}/gasmeterpoints`;
  if (siteData.utility === 'Electricity') {
    url = `${seaglassURL}sgCore/products/${siteData.meterid}/elecmeterpoints`;
  }
  return new Promise((resolve) => {
    POSTSeaglass(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          if (response.response.data && response.response.data.length) {

            let correctIndex = 0;
            console.log("starting....");
            for (
              let index = 0;
              index < response.response.data.length;
              index++
            ) {
              if (siteData.sourceid === response.response.data[index].id) {
                correctIndex = index;
                console.log("Correct index is " + correctIndex);
              } else {
                console.log(
                  "source id: " +
                    siteData.sourceid +
                    " id:" +
                    response.response.data[index].id
                );
              }
            }
            const responseData = response.response.data[correctIndex];
            console.log('Product Data :', responseData);
            if (siteData.utility === 'Electricity') {
              resolve({
                meterPointRef: responseData.mpancore,
                estimatedAnnualUsage: responseData.estimatedannualconsumption,
              });
            }
            resolve({
              meterPointRef: responseData.meterpointreference,
              estimatedAnnualUsage: responseData.supplymeterpointaq,
            });
          }
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
        resolve({
          meterPointRef: '',
          estimatedAnnualUsage: '',
        });
      })
      .catch((error) => {
        handleException(error);
        resolve({
          meterPointRef: '',
          estimatedAnnualUsage: '',
        });
      });
  });
};

// set contactInfo in localstorage
export const setUserContactInfo = (contactObj) => {
  localStorage.setItem('userContactInfo', JSON.stringify(contactObj));
};

// get contactInfo from localstorage
export const getUserContactInfo = () =>
  JSON.parse(localStorage.getItem('userContactInfo'));

// Validate with face or touch id
export const validateWithFaceOrTouchId = () => {
  return new Promise((resolve) => {
    // Check whether touch id or face id is available on device or not.
    window.Fingerprint.isAvailable(
      // isAvailableSuccess callback
      (result) => {
        console.log(result);
        const authenticateType =
          result === 'face' ? 'Face ID' : 'Touch ID/fingerprint';
        // Show popup to authenticate touch or face id.
        window.Fingerprint.show(
          {
            disableBackup: true,
            fallbackButtonTitle: 'Cancel',
            cancelButtonTitle: 'Cancel',
            description: `To login quickly please use your ${authenticateType} for authentication`,
          },
          // successCallback,
          () => {
            setTimeout(() => {
              resolve({ return: true });
              return true;
            }, 2000);
          },
          // errorCallback
          (error) => {
            resolve({ return: false });
            return false;
          }
        );
      },
      // isAvailableError callback
      (message) => {
        console.log(message);
        resolve({ return: false });
        return false;
      }
    );
  });
};
